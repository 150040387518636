import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AuthenticationRoutes from './AuthenticationRoutes';
// import Expenses from 'views/pages/Expenses';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// pages routing

// Sales Management
const NewSale = Loadable(
  lazy(() => import('views/pages/SalesManagement/NewSale'))
);
const SalesInvoice = Loadable(
  lazy(() => import('views/pages/SalesManagement/SalesInvoice'))
);
const CustomerList = Loadable(
  lazy(() => import('views/pages/SalesManagement/CustomerList'))
);

// product management
const StockStatus = Loadable(
  lazy(() => import('views/pages/ProductManagement/StockStatus'))
);
const PurchasedProduct = Loadable(
  lazy(() => import('views/pages/ProductManagement/PurchasedProduct'))
);
const ProductList = Loadable(
  lazy(() => import('views/pages/ProductManagement/ProductList'))
);
const Uom = Loadable(lazy(() => import('views/pages/ProductManagement/Uom')));

// office management
const Employees = Loadable(
  lazy(() => import('views/pages/OfficeManagement/Employees'))
);
const Salaries = Loadable(
  lazy(() => import('views/pages/OfficeManagement/Salaries'))
);
const UtilityBill = Loadable(
  lazy(() => import('views/pages/OfficeManagement/UtilityBill'))
);
const UtilityBillHome = Loadable(
  lazy(() => import('views/pages/OfficeManagement/UtilityBill/UtilityBillHome'))
);
const UtilityList = Loadable(
  lazy(() => import('views/pages/OfficeManagement/UtilityBill/UtilityList'))
);

// expense management
const Vendors = Loadable(
  lazy(() => import('views/pages/ExpenseManagement/Vendors'))
);
const ExpenseHeads = Loadable(
  lazy(() => import('views/pages/ExpenseManagement/ExpenseHeads'))
);
const ExpenseBills = Loadable(
  lazy(() => import('views/pages/ExpenseManagement/ExpenseBills'))
);
const ExpenseSummary = Loadable(
  lazy(() => import('views/pages/ExpenseManagement/ExpenseSummary'))
);

// financial
const ReceivePayment = Loadable(
  lazy(() => import('views/pages/Financial/ReceivePayment'))
);
const MakePayment = Loadable(
  lazy(() => import('views/pages/Financial/MakePayment'))
);
const Investments = Loadable(
  lazy(() => import('views/pages/Financial/Investments'))
);
const InvestmentSummary = Loadable(
  lazy(() => import('views/pages/Financial/Investments/InvestmentSummary'))
);
const Invests = Loadable(
  lazy(() => import('views/pages/Financial/Investments/Invests'))
);
const Investors = Loadable(
  lazy(() => import('views/pages/Financial/Investments/Investors'))
);
const Withdraws = Loadable(
  lazy(() => import('views/pages/Financial/Withdraws'))
);

// asset management
const AssetList = Loadable(
  lazy(() => import('views/pages/AssetManagement/AssetList'))
);
const Depreciation = Loadable(
  lazy(() => import('views/pages/AssetManagement/Depreciation'))
);
const SoldAssets = Loadable(
  lazy(() => import('views/pages/AssetManagement/SoldAssets'))
);
const DamagedAssets = Loadable(
  lazy(() => import('views/pages/AssetManagement/DamagedAssets'))
);
const AssetTitles = Loadable(
  lazy(() => import('views/pages/AssetManagement/AssetTitles'))
);
const SingleAssetView = Loadable(
  lazy(() => import('views/pages/AssetManagement/AssetList/SingleAssetView'))
);
const AssetCosts = Loadable(
  lazy(() =>
    import('views/pages/AssetManagement/AssetList/SingleAssetView/AssetCosts')
  )
);
const AssetDetailsSummary = Loadable(
  lazy(() =>
    import(
      'views/pages/AssetManagement/AssetList/SingleAssetView/AssetDetailsSummary'
    )
  )
);

// license management
const Licenses = Loadable(
  lazy(() => import('views/pages/LicenseManagement/Licenses'))
);
const LicenseProvider = Loadable(
  lazy(() => import('views/pages/LicenseManagement/LicenseProvider'))
);

// report
const ReportSummary = Loadable(
  lazy(() => import('views/pages/Report/ReportSummary'))
);
const DueReport = Loadable(lazy(() => import('views/pages/Report/DueReport')));
const Payable = Loadable(
  lazy(() => import('views/pages/Report/DueReport/Payable'))
);
const Receivable = Loadable(
  lazy(() => import('views/pages/Report/DueReport/Receivable'))
);

const AdvanceReport = Loadable(
  lazy(() => import('views/pages/Report/AdvanceReport'))
);
const AdvancePaid = Loadable(
  lazy(() => import('views/pages/Report/AdvanceReport/AdvancePaid'))
);
const AdvanceReceived = Loadable(
  lazy(() => import('views/pages/Report/AdvanceReport/AdvanceReceived'))
);

// utilities routing

// setting routing
const ManageUser = Loadable(lazy(() => import('views/setting/ManageUser')));
const SingleUser = Loadable(
  lazy(() => import('views/setting/ManageUser/SingleUser'))
);
const UserInfo = Loadable(
  lazy(() => import('views/setting/ManageUser/SingleUser/UserInfo'))
);
const UserPermission = Loadable(
  lazy(() => import('views/setting/ManageUser/SingleUser/UserPermission'))
);

// support routing
const Support = Loadable(lazy(() => import('views/support')));

// Support error routing
const Error404 = Loadable(lazy(() => import('views/Error404')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <PrivateRoute />,
  children: [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to="dashboard" replace={true} />,
        },
        {
          path: 'dashboard',
          element: <Navigate to="default" replace={true} />,
        },
        {
          path: 'dashboard/default',
          element: <DashboardDefault />,
        },
        {
          path: 'pages',
          children: [
            {
              path: 'sales-management',
              children: [
                {
                  path: 'new-sale',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['new-sale']}
                    >
                      <NewSale />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'sales-invoice',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['sales-invoice']}
                    >
                      <SalesInvoice />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'customer-list',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['customer-list']}
                    >
                      <CustomerList />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'product-management',
              children: [
                {
                  path: 'stock-status',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['stock-status']}
                    >
                      <StockStatus />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'purchased-product',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['purchased-product']}
                    >
                      <PurchasedProduct />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'product-list',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['product-list']}
                    >
                      <ProductList />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'uom',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['uom']}
                    >
                      <Uom />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'office-management',
              children: [
                {
                  path: 'employees',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['employees']}
                    >
                      <Employees />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'salaries',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['salaries']}
                    >
                      <Salaries />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'utility-bill',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['utility-bill']}
                    >
                      <UtilityBill />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <UtilityBillHome />,
                    },
                    {
                      path: 'utility-list',
                      element: <UtilityList />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'expense-management',
              children: [
                {
                  path: 'vendors',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['vendors']}
                    >
                      <Vendors />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'expense-heads',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['expense-heads']}
                    >
                      <ExpenseHeads />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'expense-bills',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['expense-bills']}
                    >
                      <ExpenseBills />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'expense-summary',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['expense-summary']}
                    >
                      <ExpenseSummary />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'financial',
              children: [
                {
                  path: 'make-payment',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['make-payment']}
                    >
                      <MakePayment />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'receive-payment',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['receive-payment']}
                    >
                      <ReceivePayment />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'investments',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['investments']}
                    >
                      <Investments />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <InvestmentSummary />,
                    },
                    {
                      path: 'invests',
                      element: <Invests />,
                    },
                    {
                      path: 'investors',
                      element: <Investors />,
                    },
                  ],
                },
                {
                  path: 'withdraws',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['withdraws']}
                    >
                      <Withdraws />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'asset-management',
              children: [
                {
                  path: 'asset-list',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['asset-list']}
                    >
                      <AssetList />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'asset-list/:id',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['asset-list']}
                    >
                      <SingleAssetView />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <AssetCosts />,
                    },
                    {
                      path: 'summary',
                      element: <AssetDetailsSummary />,
                    },
                  ],
                },
                {
                  path: 'depreciation',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['depreciation']}
                    >
                      <Depreciation />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'sold-assets',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['sold-assets']}
                    >
                      <SoldAssets />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'damaged-assets',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['damaged-assets']}
                    >
                      <DamagedAssets />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'asset-titles',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['asset-titles']}
                    >
                      <AssetTitles />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'license-management',
              children: [
                {
                  path: 'licenses',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['licenses']}
                    >
                      <Licenses />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'license-provider',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['license-provider']}
                    >
                      <LicenseProvider />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'report',
              children: [
                {
                  path: 'summary',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['summary']}
                    >
                      <ReportSummary />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'due-report',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['due-report']}
                    >
                      <DueReport />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <Payable />,
                    },
                    {
                      path: 'receivable',
                      element: <Receivable />,
                    },
                  ],
                },
                {
                  path: 'advance-report',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['advance-report']}
                    >
                      <AdvanceReport />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <AdvancePaid />,
                    },
                    {
                      path: 'advance-received',
                      element: <AdvanceReceived />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'utils',
          children: [
            {
              path: 'setting',
              children: [
                {
                  path: 'manage-user',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['manage-user']}
                    >
                      <ManageUser />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'manage-user/:id',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['manage-user']}
                    >
                      <SingleUser />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <UserInfo />,
                    },
                    {
                      path: 'permission',
                      element: <UserPermission />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'support',
              element: <Support />,
            },
          ],
        },
        {
          path: '/*',
          element: <Error404 />,
        },
      ],
    },
  ],
};

export default MainRoutes;
