// assets
import {
  IconGasStation,
  IconDropletBolt,
  IconHome2,
  IconListDetails,
  IconTruck,
  IconLicense,
  IconMoneybag,
  IconReport,
} from '@tabler/icons-react';

// constant
const icons = {
  IconGasStation,
  IconDropletBolt,
  IconHome2,
  IconTruck,
  IconListDetails,
  IconLicense,
  IconMoneybag,
  IconReport,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Pages',
  type: 'group',
  access: ['super_admin'],
  children: [
    {
      id: 'sales-management',
      title: 'Sales Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconGasStation,
      access: ['super_admin'],
      children: [
        {
          id: 'new-sale',
          title: 'New Sale',
          type: 'item',
          url: '/pages/sales-management/new-sale',
          access: ['super_admin'],
        },
        {
          id: 'sales-invoice',
          title: 'Sales Invoice',
          type: 'item',
          url: '/pages/sales-management/sales-invoice',
          access: ['super_admin'],
        },
        {
          id: 'customer-list',
          title: 'Customer List',
          type: 'item',
          url: '/pages/sales-management/customer-list',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'product-management',
      title: 'Product Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconDropletBolt,
      access: ['super_admin'],
      children: [
        {
          id: 'stock-status',
          title: 'Stock Status',
          type: 'item',
          url: '/pages/product-management/stock-status',
          access: ['super_admin'],
        },
        {
          id: 'purchased-product',
          title: 'Purchased Product',
          type: 'item',
          url: '/pages/product-management/purchased-product',
          access: ['super_admin'],
        },
        {
          id: 'product-list',
          title: 'Product List',
          type: 'item',
          url: '/pages/product-management/product-list',
          access: ['super_admin'],
        },
        {
          id: 'uom',
          title: 'Unit of Measurement',
          type: 'item',
          url: '/pages/product-management/uom',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'office-management',
      title: 'Office Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconHome2,
      access: ['super_admin'],
      children: [
        {
          id: 'employees',
          title: 'Employees',
          type: 'item',
          url: '/pages/office-management/employees',
          access: ['super_admin'],
        },
        {
          id: 'salaries',
          title: 'Salaries',
          type: 'item',
          url: '/pages/office-management/salaries',
          access: ['super_admin'],
        },
        {
          id: 'utility-bill',
          title: 'Utility Bill',
          type: 'item',
          url: '/pages/office-management/utility-bill',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'expense-management',
      title: 'Expense Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconListDetails,
      access: ['super_admin'],
      children: [
        {
          id: 'vendors',
          title: 'Vendors',
          type: 'item',
          url: '/pages/expense-management/vendors',
          access: ['super_admin'],
        },
        {
          id: 'expense-heads',
          title: 'Expense Heads',
          type: 'item',
          url: '/pages/expense-management/expense-heads',
          access: ['super_admin'],
        },
        {
          id: 'expense-bills',
          title: 'Expense Bills',
          type: 'item',
          url: '/pages/expense-management/expense-bills',
          access: ['super_admin'],
        },
        {
          id: 'expense-summary',
          title: 'Expense Summary',
          type: 'item',
          url: '/pages/expense-management/expense-summary',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'financial',
      title: 'Financial',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconMoneybag,
      access: ['super_admin'],
      children: [
        {
          id: 'receive-payment',
          title: 'Receive a Payment',
          type: 'item',
          url: '/pages/financial/receive-payment',
          access: ['super_admin'],
        },
        {
          id: 'make-payment',
          title: 'Make a Payment',
          type: 'item',
          url: '/pages/financial/make-payment',
          access: ['super_admin'],
        },
        {
          id: 'investments',
          title: 'Investments',
          type: 'item',
          url: '/pages/financial/investments',
          access: ['super_admin'],
        },
        {
          id: 'withdraws',
          title: 'Withdraws',
          type: 'item',
          url: '/pages/financial/withdraws',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'asset-management',
      title: 'Asset Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconTruck,
      access: ['super_admin'],
      children: [
        {
          id: 'asset-list',
          title: 'Asset List',
          type: 'item',
          url: '/pages/asset-management/asset-list',
          access: ['super_admin'],
        },
        {
          id: 'depreciation',
          title: 'Depreciation',
          type: 'item',
          url: '/pages/asset-management/depreciation',
          access: ['super_admin'],
        },
        {
          id: 'damaged-assets',
          title: 'Damaged Assets',
          type: 'item',
          url: '/pages/asset-management/damaged-assets',
          access: ['super_admin'],
        },
        {
          id: 'sold-assets',
          title: 'Sold Assets',
          type: 'item',
          url: '/pages/asset-management/sold-assets',
          access: ['super_admin'],
        },
        {
          id: 'asset-titles',
          title: 'Asset Titles',
          type: 'item',
          url: '/pages/asset-management/asset-titles',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'license-management',
      title: 'License Management',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconLicense,
      access: ['super_admin'],
      children: [
        {
          id: 'licenses',
          title: 'Licenses',
          type: 'item',
          url: '/pages/license-management/licenses',
          access: ['super_admin'],
        },
        {
          id: 'license-provider',
          title: 'License Provider',
          type: 'item',
          url: '/pages/license-management/license-provider',
          access: ['super_admin'],
        },
      ],
    },
    {
      id: 'report',
      title: 'Report',
      type: 'collapse',
      color: '#2F0F5D',
      icon: icons.IconReport,
      access: ['super_admin'],
      children: [
        {
          id: 'summary',
          title: 'Summary',
          type: 'item',
          url: '/pages/report/summary',
          access: ['super_admin'],
        },
        {
          id: 'due-report',
          title: 'Due Report',
          type: 'item',
          url: '/pages/report/due-report',
          access: ['super_admin'],
        },
        {
          id: 'advance-report',
          title: 'Advance Report',
          type: 'item',
          url: '/pages/report/advance-report',
          access: ['super_admin'],
        },
      ],
    },
  ],
};

export default pages;
